import { useFormik, FormikProvider, FieldArray } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import 'utils/yupExtensions';
import useManageForms from 'hooks/useManageForms';
import { Subtitle } from 'common/text';
import uuidv4 from 'utils/uuid';
import ButtonCircle from 'common/buttons/circle';
import Delete from 'common/buttons/delete';
// inputs
import Input from 'components/forms/inputs/input';
import Select from 'components/forms/inputs/select';
import SelectFetch from 'components/forms/inputs/fetchSelect';
import Submit from 'components/forms/inputs/submit';
interface PropsI {
	data: any;
	id: any;
}
const EditOrderForm = (props: PropsI) => {
	const { data, id } = props;
	const { edit } = useManageForms();
	console.log('data', data);
	const formik = useFormik({
		initialValues: {
			orderStatusId: data.orderStatusId || '',
			orderStatusLabel: data.orderStatusLabel || '',
			businessUnit: data.businessUnit,
			customer: data.customer,
			customerPhone: data.customerPhone,
			customerAddress: data.customerAddress,
			products: [...data.products],
			paymentMethodId: data.paymentMethodId || '',
			carrierGuide: data.carrierGuide,
			comments: data.comments,
		},
		validationSchema: Yup.object({
			orderStatusId: Yup.string().required('Este campo es obligatorio'),
			businessUnit: Yup.string().required('Este campo es obligatorio'),
			customer: Yup.string().required('Este campo es obligatorio'),
			customerPhone: Yup.number().required('Este campo es obligatorio'),
			customerAddress: Yup.string().required('Este campo es obligatorio'),
			products: Yup.array().of(
				Yup.object().shape({
					productId: Yup.string().required('Este campo es obligatorio'),
					quantity: Yup.number().required('Este campo es obligatorio'),
					price: Yup.number().required('Este campo es obligatorio'),
				})
			),
			paymentMethodId: Yup.string().when('orderStatusId', {
				is: (value: string) => value === '665fabf1cf1f118affb6b863',
				then: schema => schema.required('Este campo es obligatorio'),
				otherwise: schema => schema,
			}),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await edit(values, `/orders/${id}`, 'Order creada', `/orders`);
			setSubmitting(false);
		},
	});
	const isLastRateEmpty = (products: any) => {
		const lastProduct = products[products.length - 1];
		return lastProduct.price === '' || lastProduct.quantity === '' || lastProduct.productId === '';
	};
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				{/* basic fields */}
				<Grid
					$columns={1}
					$margin="2rem">
					<Subtitle $separator={true}>Orden</Subtitle>
					<SelectFetch
						url="/order-status"
						placeholder="Estado de la orden"
						name="orderStatusId"
						searchedValue="_id"
						searchedLabel="name"
					/>
					<Select
						name="businessUnit"
						placeholder="Unidad de negocio"
						options={[
							{ value: 'Keralazzio', label: 'Keralazzio' },
							{ value: 'Nanoplastec', label: 'Nanoplastec' },
						]}
					/>

					<Subtitle $separator={true}>Cliente</Subtitle>
					<Input
						nameData="customer"
						placeholderData="Cliente"
						typeData="text"
						label="Cliente"
					/>
					<Input
						nameData="customerPhone"
						placeholderData="cliente"
						typeData="text"
						label="Teléfono cliente"
					/>
					<Input
						nameData="customerAddress"
						placeholderData="Dirección"
						typeData="text"
						label="Dirección"
					/>
				</Grid>

				<FieldArray
					name="products"
					render={arrayHelpers => {
						return (
							<>
								<Subtitle
									$separator={true}
									$center="space-between">
									Productos
									<ButtonCircle
										disabled={isLastRateEmpty(formik.values.products)}
										$icon="add"
										onClick={() => {
											arrayHelpers.push({
												productId: '',
												quantity: '',
												price: '',
												key: uuidv4(),
											});
										}}
									/>
								</Subtitle>
								{formik.values.products.map((product, index) => (
									<Grid
										$columns={1}
										$separator="2rem"
										$line={true}
										key={product.key}>
										<SelectFetch
											url="/products"
											placeholder="Producto"
											name={`products[${index}].productId`}
											searchedValue="_id"
											searchedLabel="name"
										/>
										<Input
											nameData={`products[${index}].quantity`}
											placeholderData="Piezas"
											typeData="number"
											label="Piezas"
										/>
										<Input
											nameData={`products[${index}].price`}
											placeholderData="Precio"
											typeData="number"
											label="Precio"
										/>
										<Flex $h="flex-end">
											<Delete
												disabled={formik.values.products.length === 1}
												onClick={() => arrayHelpers.remove(index)}
											/>
										</Flex>
									</Grid>
								))}
							</>
						);
					}}
				/>

				<Grid
					$columns={1}
					$separator="2rem">
					<Subtitle $separator={true}>Pago y envio</Subtitle>
					<SelectFetch
						url="/payment-methods"
						placeholder="Método de pago"
						name="paymentMethodId"
						searchedValue="_id"
						searchedLabel="bank"
					/>
					<Input
						nameData="carrierGuide"
						placeholderData="Guía de envío"
						typeData="text"
						label="Guía de envío"
					/>
					<Input
						nameData="comments"
						placeholderData="Comentarios"
						typeData="text"
						label="Comentarios"
					/>
				</Grid>

				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						Salvar
					</Submit>
				</Flex>
			</form>
			{formik.errors && <pre>{JSON.stringify(formik.errors, null, 2)}</pre>}
		</FormikProvider>
	);
};
export default EditOrderForm;
